<template>
  <div class="errormessage-container"
       :class="{ 'vertical': vertical, 'small': size == 'small', 'medium': size == 'medium'}">
    <img :src="mascot" alt="Error" />
    <div class="errormessage-content">
      <h2>{{ $t('error.title') }}</h2>
      <p class="text">
        <span>{{  props.errorMessage ? props.errorMessage : processedError?.identifier ? t('error.' + processedError?.identifier?.toLowerCase()) : t('error.generic') }}</span>
        <span v-if="processedError?.status" class="status">Status Code: {{ processedError.status }}</span>
      </p>
      <p class="error-text" v-if="processedError?.errors" v-for="errortext in processedError?.errors">{{ errortext }}</p>
      <ButtonSubmit v-if="onRetry" class="submit-button" @click="onRetry" :title="$t('error.retry')"
                    :loading="status == 'pending'"></ButtonSubmit>
    </div>
  </div>
</template>
<script lang="ts" setup>

import walkableMascotSad from '~/assets/walkable-mascot-sad.svg';
import bikeableMascotSad from '~/assets/bikeable-mascot-sad.svg';

import { FetchError } from 'ofetch';
const props = defineProps<{
  size?: 'small' | 'medium' | 'large';
  vertical?: boolean;
  error?: FetchError;
  errorMessage?: string;
  onRetry?: () => void;
  status?: string;
}>();

const platform = usePlatformSettings().platformName;
const mascot = platform.value == 'walkable' ? walkableMascotSad : bikeableMascotSad;
const t = useI18n().t;

const processedError = computed(() => {
  if(!props.error) {
    return undefined;
  }
  if (props.error.data) {
    return props.error.data as ApiError;
  } else {
    return ;
  }
});

</script>
<style scoped lang="scss">


.errormessage-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 250px;
}

.text {
  font-size: 1.8rem;
  line-height: 2rem;
  font-style: italic;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.status {
  font-size: 1rem;
  font-weight: 600;
  font-style: italic;
}

.error-text {
  font-size: 1rem;
  font-style: italic;
}

.vertical {
  flex-direction: column;
}

.small {

  .errormessage-content {
    min-width: 175px;
  }

  .text {
    font-size: 1rem;
    line-height: 1.1rem;

  }

  .status {
    font-size: 0.8rem;
  }

  .error-text {
    font-size: 0.6rem;
    font-style: italic;
  }

  img {
    width: 120px;
  }

  h2 {
    font-size: 1.2rem;
  }
}

.medium {

  .errormessage-content {
    min-width: 200px;
  }

  .text {
    font-size: 1.2rem;
    line-height: 1.4rem;

  }

  .status {
    font-size: 1rem;
  }

  .error-text {
    font-size: 1rem;
    font-style: italic;
  }

  img {
    width: 240px;
  }

  h2 {
    font-size: 1.5rem;
  }
}

img {
  width: 360px;
}

.errormessage-container.vertical {
  gap: 0;
}

.errormessage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 2rem;

  .errormessage-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

}
</style>
